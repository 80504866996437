/* global google */
// Don't remove this comment, this is to prevent the eslint error for google not found.

var videoElement;
var adsLoaded = false;
var adDisplayContainer;
var adsLoader;
var adsManager;
var adContainer;
var isAdPlaying;
var adIsPaused = false;
var adVideoElement;
var adTimerInterval;
var adTimer;
var playPauseButton;
var muteButton;
var isMuted = false;
var midRollCalled;
var adInitialized = false;
var isIOSDevice = false;
var isFullScreen = false;
let retryCount = 0;
const maxRetries = 3;
let adsRequest;

const adInitializedEvent = new Event("adInitialized");

export const initializeAds = (
  videoElem,
  container,
  adTagUrl,
  midRoll,
  isIOS
) => {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.ima) {
      reject(new Error("Google IMA SDK not loaded"));
      return;
    }

    videoElement = videoElem;
    adContainer = container;
    midRollCalled = midRoll;
    isIOSDevice = isIOS;

    if (!videoElement) {
      reject(new Error("Video element is not defined"));
      return;
    }

    adContainer.addEventListener("click", adContainerClick);
    adContainer.addEventListener("dblclick", handleDoubleClick);

    google.ima.settings.setVpaidMode(
      google.ima.ImaSdkSettings.VpaidMode.ENABLED
    );
    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
    adDisplayContainer = new google.ima.AdDisplayContainer(
      adContainer,
      videoElement
    );
    adsLoader = new google.ima.AdsLoader(adDisplayContainer);

    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      (event) => {
        onAdsManagerLoaded(event);
        resolve();
      },
      false
    );

    adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      (event) => {
        onAdError(event);
        videoElement.removeEventListener("ended", onContentComplete);
        window.removeEventListener("resize", onWindowResize);
        reject(new Error(`Ad Error: ${event.getError().toString()}`));
      },
      false
    );

    videoElement.addEventListener("ended", onContentComplete);
    window.addEventListener("resize", onWindowResize);

    adsRequest = new google.ima.AdsRequest();
    adsRequest.adTagUrl =
      window.location.hostname === "localhost"
        ? "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
        : adTagUrl;
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight;

    if (isIOSDevice) {
      adsRequest.setAdWillPlayMuted(true);
    }

    adsLoader.requestAds(adsRequest);
    adInitialized = true;
    window.dispatchEvent(adInitializedEvent);
  });
};

const onContentComplete = () => {
  if (adsLoader) {
    adsLoader.contentComplete();
  }
};

const onWindowResize = () => {
  if (adsManager) {
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    adsManager.resize(width, height, google.ima.ViewMode.FULLSCREEN);
  }
};

function resizeAds() {
  if (adsManager) {
    isFullScreen =
      document.fullscreenElement === videoElement ||
      document.webkitFullscreenElement === videoElement ||
      document.mozFullScreenElement === videoElement ||
      document.msFullscreenElement === videoElement ||
      (isIOSDevice &&
        videoElement.clientHeight === window.innerHeight &&
        videoElement.clientWidth === window.innerWidth);
    if (isFullScreen) {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.FULLSCREEN
      );
    } else {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.NORMAL
      );
    }
  }
}

async function onAdsManagerLoaded(adsManagerLoadedEvent) {
  var adsRenderingSettings = new google.ima.AdsRenderingSettings();
  adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
  if (!isIOSDevice) {
    adsRenderingSettings.enablePreloading = true;
  }
  adsManager = adsManagerLoadedEvent.getAdsManager(
    videoElement,
    adsRenderingSettings
  );

  resizeAds();

  if (
    videoElement &&
    adContainer &&
    adsManager &&
    adInitialized &&
    midRollCalled
  ) {
    if (!videoElement.muted && isIOSDevice) {
      videoElement.muted = true;
    }
    loadAds(adsManagerLoadedEvent);
  }

  adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested
  );

  const events = [
    google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
    google.ima.AdEvent.Type.CLICK,
    google.ima.AdEvent.Type.COMPLETE,
    google.ima.AdEvent.Type.FIRST_QUARTILE,
    google.ima.AdEvent.Type.LOADED,
    google.ima.AdEvent.Type.MIDPOINT,
    google.ima.AdEvent.Type.PAUSED,
    google.ima.AdEvent.Type.RESUMED,
    google.ima.AdEvent.Type.STARTED,
    google.ima.AdEvent.Type.THIRD_QUARTILE,
  ];

  for (const eventType of events) {
    adsManager.addEventListener(eventType, onAdEvent);
  }
}

function createPlayPauseButton() {
  if (playPauseButton) {
    playPauseButton.remove();
  }
  playPauseButton = document.createElement("div");
  playPauseButton.id = "ad-play-pause-button";
  playPauseButton.style.position = "absolute";
  playPauseButton.style.bottom = "20px";
  playPauseButton.style.left = "10px";
  playPauseButton.style.width = "30px";
  playPauseButton.style.height = "30px";
  playPauseButton.style.backgroundColor = "rgba(0, 0, 0, 0.4)";
  playPauseButton.style.borderRadius = "50px";
  playPauseButton.style.backgroundImage = `url(${require("../assets/icons/pause.png")})`;
  playPauseButton.style.backgroundSize = "contain";
  playPauseButton.style.backgroundRepeat = "no-repeat";
  playPauseButton.style.backgroundPosition = "center";
  playPauseButton.style.cursor = "pointer";
  playPauseButton.style.zIndex = "5";
  playPauseButton.setAttribute("role", "button");
  playPauseButton.setAttribute("aria-label", "Play/Pause Ad");

  playPauseButton.addEventListener("click", togglePlayPause);
  if (adContainer) {
    adContainer.appendChild(playPauseButton);
  }
}

function updatePlayPauseButton(paused) {
  if (playPauseButton) {
    const icon = paused
      ? require("../assets/icons/play.png")
      : require("../assets/icons/pause.png");
    playPauseButton.style.backgroundImage = `url(${icon})`;
  }
}

function togglePlayPause() {
  if (!adsManager) {
    console.error("AdsManager is not available");
    return;
  }

  try {
    if (adIsPaused) {
      adsManager.resume();
      adVideoElement.play();
      adIsPaused = false;
      updatePlayPauseButton(false);
    } else {
      adsManager.pause();
      adVideoElement.pause();
      adIsPaused = true;
      updatePlayPauseButton(true);
    }
  } catch (error) {
    console.error("Error toggling play/pause:", error);
  }
}

function removePlayPauseButton() {
  if (playPauseButton) {
    playPauseButton.remove();
    playPauseButton = null;
  }
}

function createMuteButton() {
  if (muteButton) {
    muteButton.remove();
  }
  muteButton = document.createElement("div");
  muteButton.id = "ad-mute-button";
  muteButton.style.position = "absolute";
  muteButton.style.bottom = "20px";
  muteButton.style.left = "50px";
  muteButton.style.width = "30px";
  muteButton.style.height = "30px";
  muteButton.style.backgroundColor = "rgba(0, 0, 0, 0.4)";
  muteButton.style.borderRadius = "50px";
  muteButton.style.backgroundImage = isIOSDevice
    ? `url(${require("../assets/icons/mute.png")})`
    : `url(${require("../assets/icons/volume.png")})`;
  muteButton.style.backgroundSize = "contain";
  muteButton.style.backgroundRepeat = "no-repeat";
  muteButton.style.backgroundPosition = "center";
  muteButton.style.cursor = "pointer";
  muteButton.style.zIndex = "5";
  muteButton.setAttribute("role", "button");
  muteButton.setAttribute("aria-label", isMuted ? "Unmute Ad" : "Mute Ad");

  muteButton.addEventListener("click", toggleMute);
  if (adContainer) {
    adContainer.appendChild(muteButton);
  }
}

function updateMuteButton() {
  if (muteButton) {
    const icon = isMuted
      ? require("../assets/icons/mute.png")
      : require("../assets/icons/volume.png");
    muteButton.style.backgroundImage = `url(${icon})`;
  }
}

function toggleMute() {
  if (!adsManager || !adVideoElement) {
    console.error("AdsManager or adVideoElement is not available");
    return;
  }

  isMuted = !isMuted;
  adVideoElement.muted = isMuted;
  adsManager.setVolume(isMuted ? 0 : 1);
  updateMuteButton();
}

function removeMuteButton() {
  if (muteButton) {
    muteButton.remove();
    muteButton = null;
  }
}

function createAdTimer() {
  if (adTimer) {
    adTimer.remove();
  }
  adTimer = document.createElement("div");
  adTimer.id = "ad-timer";
  adTimer.style.position = "absolute";
  adTimer.style.bottom = "55px";
  adTimer.style.width = "100px";
  adTimer.style.height = "30px";
  adTimer.style.left = "10px";
  adTimer.style.color = "#ffffff";
  adTimer.style.fontSize = "14px";
  adTimer.style.backgroundColor = "rgba(0, 0, 0, 0.4)";
  adTimer.style.borderRadius = "5px";
  adTimer.style.display = "flex";
  adTimer.style.justifyContent = "center";
  adTimer.style.alignItems = "center";
  adTimer.style.zIndex = "5";

  if (adContainer && adVideoElement) {
    adContainer.appendChild(adTimer);
  }
}

function updateAdTimer() {
  if (adTimer && adVideoElement) {
    const remainingTime = Math.max(
      0,
      Math.ceil(adVideoElement.duration - adVideoElement.currentTime)
    );

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    adTimer.textContent = `Ends in ${minutes}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}

function startAdTimer() {
  createAdTimer();
  adTimerInterval = setInterval(updateAdTimer, 1000);
}

function stopAdTimer() {
  if (adTimerInterval) {
    clearInterval(adTimerInterval);
    adTimerInterval = null;
  }
  if (adTimer) {
    adTimer.remove();
  }
}

async function onAdEvent(adEvent) {
  console.log("adEvent: ", adEvent.type);

  var ad = adEvent.getAd();
  switch (adEvent.type) {
    case google.ima.AdEvent.Type.LOADED:
      if (!ad.isLinear()) {
        try {
          await videoElement.play();
        } catch (error) {
          console.error("Error playing video:", error);
        }
      }
      break;
    case google.ima.AdEvent.Type.STARTED:
      if (adContainer) {
        adVideoElement =
          adContainer.querySelector('video[style*="display: block"]') ||
          adContainer.querySelector("video[src]");
        createPlayPauseButton();
        createMuteButton();
        startAdTimer();
        retryCount = 0;
      }
      break;
    case google.ima.AdEvent.Type.PAUSED:
      adIsPaused = true;
      updatePlayPauseButton(true);
      break;
    case google.ima.AdEvent.Type.RESUMED:
      adIsPaused = false;
      updatePlayPauseButton(false);
      break;
    case google.ima.AdEvent.Type.COMPLETE:
    case google.ima.AdEvent.Type.SKIPPED:
    case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
      stopAdTimer();
      removePlayPauseButton();
      removeMuteButton();
      midRollCalled = false;
      adIsPaused = false;
      retryCount = 0;
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }
      break;

    default:
      break;
  }
}

const adFailedEvent = new Event("adFailed");

function onAdError(adErrorEvent) {
  const adError = adErrorEvent.getError();
  console.error("adError: ", adError);
  window.dispatchEvent(adFailedEvent);

  if (adError) {
    const errorCode = adError.getErrorCode();
    if (
      errorCode === google.ima.AdError.ErrorCode.VAST_MEDIA_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.VAST_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.ADS_REQUEST_NETWORK_ERROR ||
      errorCode === google.ima.AdError.ErrorCode.FAILED_TO_REQUEST_ADS
    ) {
      if (retryCount < maxRetries) {
        retryCount++;
        setTimeout(() => {
          adsLoader.contentComplete();
          adsLoader.requestAds(adsRequest);
        }, 5000);
      } else {
        cancelAds();
        onContentResumeRequested();
      }
    } else {
      cancelAds();
      onContentResumeRequested();
    }
  } else {
    cancelAds();
    onContentResumeRequested();
  }
}
const adPlayingEvent = new Event("adPlaying");
const adStoppedEvent = new Event("adStopped");

async function onContentPauseRequested() {
  if (videoElement) {
    adContainer.style.zIndex = 3;
    isAdPlaying = true;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "none";
      controls.style.opacity = 0;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "none";
      overlaidControls.style.opacity = 0;
    }

    window.dispatchEvent(adPlayingEvent);

    try {
      await videoElement.pause();
    } catch (error) {
      console.error("Error pausing video:", error);
    }
  }
}

async function onContentResumeRequested() {
  if (videoElement) {
    adContainer.style.zIndex = -1;
    isAdPlaying = false;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "flex";
      controls.style.opacity = 1;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "flex";
      overlaidControls.style.opacity = 1;
    }

    window.dispatchEvent(adStoppedEvent);

    try {
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }
      await videoElement.play();
    } catch (error) {
      console.error("Error resuming video playback:", error);
    }
  }
}

function handleDoubleClick(event) {
  if (isAdPlaying) {
    event.preventDefault();
    event.stopPropagation();
  }
}

async function adContainerClick(event) {
  if (videoElement) {
    if (videoElement.paused) {
      try {
        await videoElement.play();
      } catch (error) {
        console.error("Error resuming video playback:", error);
      }
    } else {
      try {
        await videoElement.pause();
      } catch (error) {
        console.error("Error pausing video:", error);
      }
    }
  }
}

export const loadAds = async (event) => {
  if (adsLoaded) {
    return;
  }

  event.preventDefault();

  if (!videoElement || !adDisplayContainer) {
    console.error("Video element or ad display container is not available");
    return;
  }

  if (adInitialized) {
    adDisplayContainer.initialize();
    try {
      setTimeout(async () => {
        if (adsManager && !adsLoaded) {
          if (isFullScreen) {
            await adsManager.init(
              videoElement.clientWidth,
              videoElement.clientHeight,
              google.ima.ViewMode.FULLSCREEN
            );
          } else {
            await adsManager.init(
              videoElement.clientWidth,
              videoElement.clientHeight,
              google.ima.ViewMode.NORMAL
            );
          }
          await adsManager.start();
        }
        adsLoaded = true;
      }, 500);
    } catch (adError) {
      console.error("AdsManager could not be started: ", adError);
      try {
        await videoElement.play();
      } catch (playError) {
        console.error("Error resuming video playback:", playError);
      }
    }
  }
};

export const cancelAds = () => {
  if (adsManager) {
    adsManager.destroy();
    adsManager = null;
  }
  adInitialized = false;
  midRollCalled = false;
  adsLoaded = false;
  clearAllEventListeners();
};

function clearAllEventListeners() {
  if (adContainer) {
    adContainer.removeEventListener("click", adContainerClick);
    adContainer.removeEventListener("dblclick", handleDoubleClick);
  }
  if (playPauseButton) {
    playPauseButton.removeEventListener("click", togglePlayPause);
  }
  if (muteButton) {
    muteButton.removeEventListener("click", toggleMute);
  }

  if (videoElement) {
    videoElement.removeEventListener("ended", onContentComplete);
  }
  window.removeEventListener("resize", onWindowResize);
  if (adsManager) {
    adsManager.removeEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      onAdError
    );
    const events = [
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.CLICK,
      google.ima.AdEvent.Type.COMPLETE,
      google.ima.AdEvent.Type.FIRST_QUARTILE,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.MIDPOINT,
      google.ima.AdEvent.Type.PAUSED,
      google.ima.AdEvent.Type.RESUMED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.THIRD_QUARTILE,
    ];
    for (const eventType of events) {
      adsManager.removeEventListener(eventType, onAdEvent);
    }
  }
}
