import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const regionsData = useSelector((state) => state.region.regionsData);

  const sessionId = localStorage.getItem("session_id");

  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });

  const [formDataErrors, setFormDataErrors] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);

    if (
      name === "current_password" ||
      name === "password" ||
      name === "confirm_password"
    ) {
      setErrorMessage("");
    }
  };

  const validateField = (name, value) => {
    const errors = { ...formDataErrors };

    switch (name) {
      case "current_password":
        errors.current_password = value
          ? isValidPassword(value)
            ? ""
            : "Password must be 8 characters or longer and include at least one number or symbol"
          : "Please enter old password";
        break;
      case "password":
        errors.password = value
          ? isValidPassword(value)
            ? ""
            : "Password must be 8 characters or longer and include at least one number or symbol"
          : "Please enter new password";
        break;
      case "confirm_password":
        errors.confirm_password =
          value !== formData.password
            ? "Password and confirm password do not match"
            : "";
        break;
      default:
        break;
    }

    setFormDataErrors(errors);

    const isFormValid =
    Object.values(errors).every((error) => !error) &&
    Object.values(formData).every((field) => field !== "");
    setSubmitDisabled(!isFormValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userinfo = {
        password: formData.password,
        confirm_password: formData.confirm_password,
        current_password: formData.current_password,
        region: regionsData?.country_code2,
      };
      const userData = {
        access_token: access_token,
        auth_token: auth_token,
        user: userinfo,
      };

      const nextApiUrl = `${BASE_URL}users/${sessionId}/change_password`;
      const response = await axios.post(nextApiUrl, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // console.log("res", response.data);
        setSuccessMessage(response?.data?.data?.message);
        window.location.href = "/home";
      }
    } catch (error) {
      // console.error("kks:", error.response.data);
      if (error.response.status === 422) {
        if (
          error.response.data.error.message ===
            "Current password and new password shouldn't be same" ||
          error.response.data.error.message === "Invalid current password"
        ) {
          setErrorMessage(error.response.data.error.message);
        }
      }
    }
  };

  function isValidPassword(password) {
    const regex = /^(?=.*[0-9!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return regex.test(password);
  }

  const [showPasswordFields, setShowPasswordFields] = useState({
    current_password: false,
    password: false,
    confirm_password: false,
  });

  const toggleShowPassword = (field) => {
    setShowPasswordFields((prevShowPasswordFields) => ({
      ...prevShowPasswordFields,
      [field]: !prevShowPasswordFields[field],
    }));
  };

  const getInputType = (field) => {
    return showPasswordFields[field] ? "text" : "password";
  };

  const { current_password, password, confirm_password } = formData;
  const {
    current_password: currentPasswordError,
    password: passwordError,
    confirm_password: confirmPasswordError,
  } = formDataErrors;

  return (
    <>
      {/* <Header /> */}
      <div className="main ">
        <Container>
          
            <Row className="justify-content-center">
              <Col lg={5} md={6} sm={8} className="pading-botm-40">
              
                <div className="form-styling-new border-styw card-box">
                  <h4 className="text-center">Change Password</h4><hr/>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group lable-space">
                      <label htmlFor="password">Old Password</label>
                      <div className="showPassword">
                      <input
                        type={getInputType("current_password")}
                        name="current_password"
                        value={current_password}
                        onChange={handleInputChange}
                        placeholder="Old Password *"
                        autoComplete="off"
                      />
                      {showPasswordFields["current_password"] ? (
                        <div className="showIcon">
                          <FaEye
                            onClick={() => toggleShowPassword("current_password")}
                          />
                        </div>
                      ) : (
                        <div className="showIcon">
                          <FaEyeSlash
                            onClick={() => toggleShowPassword("current_password")}
                          />
                        </div>
                        
                      )}
                     </div>

                      {currentPasswordError && (
                        <p className="text-left text-danger errorMessage">
                          {currentPasswordError}
                        </p>
                      )}
                    </div>

                    <div className="form-group lable-space">
                      <label htmlFor="password">New Password</label>
                      <div className="showPassword">
                      <input
                        type={getInputType("password")}
                        name="password"
                        value={password}
                        onChange={handleInputChange}
                        placeholder="New Password *"
                        autoComplete="off"
                      />

                      {showPasswordFields["password"] ? (
                        <div className="showIcon">
                          <FaEye onClick={() => toggleShowPassword("password")} />
                        </div>
                      ) : (
                        <div className="showIcon">
                          <FaEyeSlash
                            onClick={() => toggleShowPassword("password")}
                          />
                        </div>
                      )}

                      </div>

                      {passwordError && (
                        <p className="text-left text-danger errorMessage">
                          {passwordError}
                        </p>
                      )}
                    </div>

                    <div className="form-group lable-space">
                      <label htmlFor="cofirmpassword">Confirm Password</label>
                      <div className="showPassword">
                        <input
                          type={getInputType("confirm_password")}
                          name="confirm_password"
                          value={confirm_password}
                          onChange={handleInputChange}
                          placeholder="Confirm Password *"
                          autoComplete="off"
                        />

                      {showPasswordFields["confirm_password"] ? (
                        <div className="showIcon">
                          <FaEye
                          onClick={() => toggleShowPassword("confirm_password")}
                        />
                        </div>
                      ) : (
                        <div className="showIcon">
                        <FaEyeSlash
                          onClick={() => toggleShowPassword("confirm_password")}
                        />
                        </div>
                      )}

                      {confirmPasswordError && (
                        <p className="text-left text-danger errorMessage">
                          {confirmPasswordError}
                        </p>
                      )}

                    </div>

                      <p className="text-left text-danger errorMessage">
                        {errorMessage ? `${errorMessage}` : ""}
                      </p>
                      <p className="text-left text-success errorMessage">
                        {successMessage ? `${successMessage}` : ""}
                      </p>
                    </div>

                    <button
                      className="next-button-color"
                      disabled={submitDisabled}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default ChangePassword;
